import cn from 'classnames';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Icon } from './Icon';
import { Input, InputProps } from './Input';
import { Loader } from './Loader';
import { SquareIcon } from './SquareIcon';

interface SearchProps extends InputProps {
	value?: string,
	loading?: boolean,
	defaultValue?: string,
	onReset?: () => void,
	onSearch: (text: string) => void
}

export const Search: React.FC<SearchProps> = (props) => {

	const {
		value: $value,
		loading,
		defaultValue,
		onReset,
		onSearch,
		...args
	} = props;

	const [ value, setValue ] = useState(defaultValue || '');

	const debouncedSearch = useDebouncedCallback(
		(text: string) => onSearch(text),
		400
	);

	return (
		<div className={cn('search', { 'search-loading': loading, 'resetable': !!onReset })}>
			<div className="icon-container">
				<Icon name="magnifier" />
				<Loader loading={loading} />
			</div>
			{typeof $value !== 'undefined' ? (
			<Input
				{...args}
				value={$value}
				onChange={({ target }) => {
					onSearch(target.value);
				}} />
			) : (
			<Input
				{...args}
				value={value}
				onChange={({ target }) => {
					setValue(target.value);
					debouncedSearch(target.value);
				}} />
			)}
			{!!onReset && (
			<div className="search-reset">
				<SquareIcon
					icon="cross"
					onClick={() => {
						setValue('');
						debouncedSearch('');
						onReset();
					}} />
			</div>
			)}
		</div>
	);

}
