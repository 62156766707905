import dayjs from 'dayjs';

import { RequestViewComponent as RWC } from '~/services';
import { Legend, OptionView } from '~/components';

import { RequestEarnings, RequestPerson } from '../Request';

export const PersonalAppearance: RWC<'personal_appearance'> = (props) => {

	const { request: { profile, ...request } } = props;

	const {
		event_description,
		location,
		start_time,
		end_time
	} = request.personal_appearance;

	const time = dayjs(start_time * 1000);
	const timeEnd = dayjs(end_time * 1000);

	return (
		<>
			<RequestPerson
				profile={profile}
				request={request} />
			<Legend
				label="Event description"
				children={event_description} />
			<RequestEarnings
				amount={request.request_amount}>
				<OptionView
					label="Location"
					value={location?.address} />
				<OptionView
					label="Date"
					value={time.format('MMMM, D YYYY')} />
				<OptionView
					label="Time"
					value={`${time.format('h:mm a')} - ${timeEnd.format('h:mm a')}`} />
			</RequestEarnings>
		</>
	);

}
