import { useState } from 'react';

import { useAuth } from '~/store';
import { SignUpWrap } from '~/containers';

import {
	StepType,
	SignUpData,
	signUpData,
	useSignUpSteps
} from '~/services/signup';

import * as Steps from '~/containers/SignUp';

export const SignUpForm: React.FC = () => {

	const { account } = useAuth();

	const step = useState<StepType>('SignUpPhoto');

	const data = useState<SignUpData>(signUpData);

	const Container = Steps[step[0]];

	const steps = useSignUpSteps(step[0], account?.user_type);

	if (!account) {
		return null;
	}

	return (
		<SignUpWrap
			step={step}
			steps={steps}
			account={account}>
			<Container
				step={step}
				data={data}
				steps={steps}
				account={account} />
		</SignUpWrap>
	);

}
