import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { AthleteCard, Button, Search, SquareIcon, Title } from '../components';

import { Athlete, handleError, IAthlete } from '../services';
import { StateResponse } from '../types';
import { Preview, Profile } from '~/containers/Athlete';
import { PageTitle } from './Page';

export const athletesCount = (val: number): string => {

	if (!val) {
		return 'No athletes selected';
	}

	return `${val} athlete${val > 1 ? 's' : ''}`;

}

export const athletesSummary = (arr: IAthlete[]): [ number, number ] => {

	let count = 0;

	const price = arr.reduce(
		(price, { endorsement_rate }) => {
			count++;
			return price + endorsement_rate;
		},
		0
	);

	return [ count, price ];

}

const _isInArray = <T extends { id: string }>(arr: T[], item: T) => {

	const _isFound = !!arr.filter(({ id }) => id === item.id)[0];

	return _isFound;

}

const _pushOrRemove = <T extends { id: string }>(arr: T[], item: T) => {

	if (_isInArray(arr, item)) {
		return arr.filter(({ id }) => id !== item.id);
	}

	return [ item, ...arr ];

}

interface AthletesPickerProps {
	list: StateResponse<IAthlete[]>,
	shown: boolean,
	onHide: () => void
}

export const AthletesPicker: React.FC<AthletesPickerProps> = (props) => {

	const { list, onHide } = props;

	const [ selected, setSelected ] = list;

	const [ _filter, setFilter ] = useState('');

	const [ filter ] = useDebounce(_filter, 400);

	const [ athletes, setAthletes ] = useState<IAthlete[]>([]);

	const [ athlete, setAthlete ] = useState<{ id: string, tab: number } | null>(null);

	const summary = useMemo(
		() => athletesSummary(selected),
		[ selected ]
	);

	const retreive = useCallback(
		async (filter: string) => {

			try {

				const { athletes } = await Athlete.readEndorsement(filter).promise

				setAthletes(athletes);

			} catch (e) {

				handleError(e);

			}

		},
		[]
	);

	const onAthleteSelect = useCallback(
		(athlete: IAthlete, e: React.MouseEvent) => {

			e.stopPropagation();

			setSelected(_pushOrRemove(selected, athlete));

		},
		[ setSelected, selected ]
	);

	useEffect(() => {

		retreive(filter);

	}, [ retreive, filter ]);

	if (athlete) {
		return (
			<>
				{athlete.tab === 0 ? (
				<Preview
					onGoBack={() => setAthlete(null)}
					onGoServices={() => setAthlete((val) => val && ({ ...val, tab: 1 }))}
					athleteId={athlete.id} />
				) : (
				<Profile
					onGoBack={() => setAthlete((val) => val && ({ ...val, tab: 0 }))}
					athleteId={athlete.id} />
				)}
			</>
		);
	}

	return (
		<>
			<PageTitle
				title="Select athletes"
				onBack={onHide} />
			<div className="app--athletes-picker">
				<div className="app--athletes-picker-search">
					<SquareIcon
						icon="cross"
						onClick={onHide} />
					<Search
						value={_filter}
						onSearch={setFilter}
						onReset={() => setFilter('')}
						placeholder="School name, athlete name or sport" />
				</div>
				<div className="grid grid-3s">
					{athletes.map((athlete) => (
					<AthleteCard
						key={athlete.id}
						athlete={athlete}
						onClick={() => setAthlete({ id: athlete.id, tab: 0 })}
						onSelect={onAthleteSelect}
						isSelected={_isInArray(selected, athlete)} />
					))}
				</div>
				<div className="app--athletes-picker-footer">
					<div className="app--athletes-picker-footer-title">
						<Title label="Choose athletes" />
					</div>
					<div className="app--athletes-picker-footer-inner">
						<div className="app--athletes-summary">
							<p children={athletesCount(summary[0])}  />
							<p children={`$${summary[1]}`}  />
						</div>
						<Button
							label="Continue"
							variant="primary"
							onClick={onHide}
							disabled={!selected.length}
							disabledDeep />
					</div>
				</div>
			</div>
		</>
	);

}
