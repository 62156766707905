import { api, UserType } from '../';
import { DeepWritableObject } from '~/types';

import {
	BusinessStatusKeys,
	GenericServices,
	IAccount,
	IAccountAthlete,
	IAccountBusiness,
	ILocation,
	LocationType,
	RequestType,
	SocialLinksForm
} from './';

import { IRequest } from './Request';

export type UsersReadInput = {
	page: number,
	request?: string,
	page_size: number,
	user_type?: UserType,
	profile_completed?: 0 | 1 | 2,
}

export type UsersReadOutput = {
	page: number,
	users: (IAccount & { pending_payouts: number })[],
	total_pages: number
}

export type UserReadInput = {
	user_id: string
}

export type UserReadOutput = {
	amount: number,
	account: IAccount | IAccountAthlete | IAccountBusiness,
	requests: []
}

export type RequestsReadInput = {
	page: number,
	user_id: string,
	page_size: number,
	request_type?: RequestType,
	payout_status?: 1 | 0
}

export type RequestsReadOutput = {
	page: number,
	amount: number,
	requests: IRequest[],
	total_pages: number,
}

export type RequestReadOutput0 = {
	type: 'request' | 'campaign',
	requests: IRequest[],
}

export type UserCreateInput = {
	email: string,
	role: UserType
}

export type UserCreateOutput = {
	user: IAccount
}

export type TogglePaidStatusInput = {
	id: string,
	status: boolean,
	amount: number,
	user_id: string,
	campaign_id?: string
}

type Pending<T> = {
	type: T,
	paid: number,
	pending: number
}

export type RType = Exclude<RequestType, 'endorsement_campaign'>

export type StatUserType = 'fans' | 'business' | 'athletes' | 'total' | 'admins';

export type StatsOutput = {
	users_by_type: Record<StatUserType, number>,
	requests_total: number,
	requests_by_type: Record<RType, number>,
	uncompleted_profiles_by_type: Record<Exclude<StatUserType, 'admins'>, number>,
	requests_by_status: Record<BusinessStatusKeys, number>,
	paid_pending_total: Pending<'total'>,
	paid_pending_by_request_type: Pending<`payment_${RType}`>[],
}

export type PayoutAthlete = {
	id: string,
	first_name: string,
	last_name: string,
	email: string,
	mobile: string,
	past_due: number,
	last_payment_date: number,
	last_payment_amount: number,
	current_due: number
}

export type PayoutsOutput = {
	athletes_for_payout: PayoutAthlete[]
}

export type PayoutsInput = {
	amount: number,
	phone: string,
	email: string,
	athlete_id: string
}

export type UserUpdateServices = GenericServices & {
	athlete_locations: (LocationType | ILocation)[]
}

export type UserUpdateInfo = {
	school_name: string,
	school_id: string,
	school_email: string,
	sports_played: string,
	athlete_services: UserUpdateServices
}

export type UserUpdateInput = SocialLinksForm & {
	id: string,
	email: string,
	first_name: string,
	last_name: string,
	profile_photo: string,
	intro_video: string,
	phone: string,
	profile_description: string,
	instagram_link: string,
	facebook_link: string,
	tiktok_link: string,
	twitter_link: string,
	snapchat_link: string,
	athlete_info: UserUpdateInfo
}

const pushes_path = '/admin/custom-push';

export interface CustomPush {
	name: string,
	subject: string,
	message: string,
	readonly id: string,
	readonly created_at: number,
	readonly updated_at: number
}

export type CustomPushInput = DeepWritableObject<CustomPush>

export type CustomPushSend = {
	id?: string,
	subject?: string,
	message?: string,
	users_id?: string[],
	user_types_id?: UserType[]
}

export interface ReadSentPayoutsInput {
	page?: number,
	order?: 'desc' | 'asc',
}

export interface SentPayoutTarget {
	id: string,
	note: string,
	amount: number,
	receiver: string,
	payout_id: string,
	athlete_id: string,
	created_at: number,
	athlete_name: string,
	athlete_email: string,
	sender_item_id: string,
	recipient_type: 'PHONE',
	recipient_wallet: 'VENMO',
}

export interface SentPayout {
	id: string,
	subject: string,
	message: string,
	created_at: number,
	created_by: string,
	items_view?: SentPayoutTarget[],
	total_amount: number,
	pay_pal_payout_id: string,
}

export interface ReadSentPayoutsOutput {
	page: number,
	total_pages: number,
	payouts_list: SentPayout[],
}

const Pushes = {
	create: (data: CustomPushInput) => api.post<{ custom_push: CustomPush }>(
		pushes_path,
		data
	),
	read: (req?: string) => api.get<{ custom_push: CustomPush[] }>(
		`${pushes_path}/search${req ? `?request=${req}` : ''}`
	),
	update: (id: string, data: CustomPushInput) => api.put<{ custom_push: CustomPush }>(
		pushes_path,
		{ id, ...data, }
	),
	delete: (id: string) => api.del(
		`${pushes_path}?id=${id}`
	),
	send: (data: CustomPushSend) => api.post(
		`${pushes_path}/send`,
		data
	),
};

export const Admin = {

	readUsers: (params: UsersReadInput) => api.get<UsersReadOutput>(
		`/admin/user/${params.request ? 'search' : 'list-by-type'}`,
		params
	),

	readUser: (params: UserReadInput) => api.get<UserReadOutput>(
		`/admin/user`,
		params
	),

	blockUser: (params: UserReadInput) => api.post(
		`/admin/user/block`,
		params,
		{ type: 'FORM' }
	),

	readRequests: (params: RequestsReadInput) => api.get<RequestsReadOutput>(
		`/admin/requests/filter`,
		params
	),

	readRequest: (params: { id: string }) => api.get<RequestReadOutput0>(
		`/admin/requests/by-id`,
		params,
	),

	removeUser: (params: UserReadInput) => api.post(
		`/admin/user/delete`,
		params,
		{ type: 'FORM' }
	),

	readStats: () => api.get<{ counters: StatsOutput }>(
		`/admin/counters`
	),

	createUser: (params: UserCreateInput) => api.post<UserCreateOutput>(
		`/admin/user/add`,
		params,
		{ type: 'JSON' }
	),

	togglePaidStatus: ({ id, status }: TogglePaidStatusInput) => api.put(
		`/admin/payments/payout-status?id=${id}&status=${status}`,
	),

	getPayouts: () => api.get<PayoutsOutput>(`/admin/payments/payout-list`),

	sendPayouts: (payout_items: PayoutsInput[]) => api.post('/admin/payments/payout-send', { payout_items }),

	userUpdate: (data: UserUpdateInput) => api.post('/admin/user/update', data),

	uploadMedia: (type: 'photo' | 'video', file: File | File[], id: string) => api.post<{ path: string }>(
		`/admin/user/upload-${type}?athlete_id=${id}`,
		Array.isArray(file) ? {
			[type]: file[0],
			second_photo: file[1],
		} : {
			[type]: file,
		},
		{
			type: 'DATA',
		}
	),

	readSentPayouts: ({ page = 0, order = 'desc' }: ReadSentPayoutsInput) => api.get<
		ReadSentPayoutsOutput
	>(
		`/admin/payments/payout-sent-list?page=${page}&order=${order}`
	),

	Pushes,

};
