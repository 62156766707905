import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../models';

import { RefDataResponse } from '~/services';

type Keys = keyof Omit<RefDataResponse, 'stripe_public' | 'iap_apple_products' | 'intro_video'>

export const useData = () => {

	const [ state, loading ] = useSelector(
		({ data, loading }: Store.Root) => [
			data,
			loading.effects.data
		] as const
	);

	const dispatch = useDispatch<Store.Dispatch>();

	const getById = useCallback(
		<T extends Keys>(src: T, ids: string[]): RefDataResponse[T] => {

			return state[src].filter(
				({ id }) => ids.includes(id)
			) as RefDataResponse[T];

		},
		[ state ]
	);

	const sportOptions = useMemo(
		() => {

			return state.sports.map(
				(sport) => ({
					value: sport.id,
					label: sport.name,
				})
			);

		},
		[ state.sports ]
	);

	const schoolOptions = useMemo(
		() => {

			return state.schools.map(
				(school) => ({
					value: school.id,
					label: school.name,
				})
			);

		},
		[ state.schools ]
	);

	return {
		...state,
		...dispatch.data,
		loading,
		getById,
		sportOptions,
		schoolOptions,
	};

}
