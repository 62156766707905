import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { useAthlete } from '~/hooks';
import { mediaLink } from '~/utils';
import { lang } from '~/constants';

import { PageContent, PageTitle } from '../Page';
import { AthleteLabels, Button, Icon, NotFound } from '~/components';

interface PreviewProps {
	athleteId: string,
	onGoBack?: () => void,
	onGoServices?: () => void
}

export const Preview: React.FC<PreviewProps> = (props) => {

	const { athleteId, onGoBack, onGoServices } = props;

	const { push, goBack } = useHistory();

	const videoRef = useRef<HTMLVideoElement | null>(null);

	const [ playing, setPlaying ] = useState(false);

	const [ isReady, setIsReady ] = useState(false);

	const { athlete, isLoading } = useAthlete({ id: athleteId });

	if (!isLoading && !athlete) {
		return (
			<NotFound
				title={lang.NOT_FOUND_ATHLETE_TITLE}
				message={lang.NOT_FOUND_ATHLETE_MESSAGE} />
		);
	}

	const play = async () => {

		await new Promise((r) => setTimeout(r, 200));

		videoRef.current?.play();

		setPlaying(true);

	}

	if (!athlete) {
		return null;
	}

	const { athlete_info: info } = athlete;

	return (
		<>
			<PageTitle
				title={`${athlete.first_name} ${athlete.last_name}`}
				onBack={onGoBack || goBack} />
			<PageContent
				pageContentClassName="page--athlete-preview">
				<div className={cn('preview-container', 'playing')}>
					<button
						type="button"
						onClick={play}
						className={cn('play-button', { 'inactive': !!playing || !isReady })}>
						<Icon name="playBig" />
					</button>
					<video
						ref={videoRef}
						src={mediaLink(athlete.intro_video)}
						loop
						autoPlay
						onPlay={() => setPlaying(true)}
						onPause={() => setPlaying(false)}
						onCanPlay={() => setIsReady(true)}
						preload="metadata"
						controls={false}
						playsInline />
					<div className="preview-info">
						<div className="content">
							<div className="user-content">
								<img
									src={mediaLink(athlete.profile_photo, 'S')}
									alt={`${athlete.first_name}'s preview video`} />
								<div className="name">
									<h4>{athlete.first_name} {athlete.last_name}</h4>
									<AthleteLabels
										sport={[ info.sports_played, info.sports_played ]} />
								</div>
							</div>
							<Button
								label="Services"
								variant="primary"
								onClick={
									onGoServices ||
									(() => push(`/p/@${athlete.username}`))
								} />
						</div>
						<div className="overlay" />
					</div>
					<div className="preview-overlay" />
				</div>
			</PageContent>
		</>
	);

}
