interface TitleProps {
	label: string
}

export const Title: React.FC<TitleProps> = (props) => {

	const { label } = props;

	return (
		<div className="app--title mixed-fill fill-static">
			<p>{label}</p>
		</div>
	);

}
