import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { lang } from '~/constants';
import { RequestForm } from '~/services';

import {
	Input,
	Button,
	Legend,
	BigAmount,
	IconProps,
	PaddedButton
} from '~/components';

export const Shoutout: React.FC<RequestForm.Props> = (props) => {

	const { athlete, onSubmit, processes } = props;

	const { control, handleSubmit, reset } = useForm<RequestForm.Shoutout>();

	const [ count, setCount ] = useState({ name: 1, email: 1 });

	const inputIcon = useCallback(
		(key: keyof typeof count, i: number) => {

			const icons = {
				name: 'user',
				email: 'mail'
			};

			let icon = {} as IconProps;

			if (count[key] < 2 || i !== count[key] - 1) {
				icon.name = icons[key] as IconProps['name'];
				return { icon };
			}

			icon.name = 'bin';
			icon.fill = '#4a4a4a';

			const onClick = () => {
				setCount((val) => ({ ...val, [key]: val[key] - 1 }));
			}

			return {
				icon,
				iconButton: { onClick, },
			};

		},
		[ count ]
	);

	const submit = (form: RequestForm.Shoutout) => {

		onSubmit(form, () => {
			reset();
			setCount({ email: 1, name: 1 });
		});

	}

	if (!athlete) {
		return null;
	}

	return (
		<>
			<p children={lang.REQUEST_SHOUTOUT_TITLE} />
			<div className="section">
				{new Array(count.name).fill(null).map((_, i) => (
				<Input
					key={i}
					name={`names_in_shoutout.${i}`}
					rules={{ required: true }}
					control={control}
					placeholder={`Name #${i + 1}`}
					{...inputIcon('name', i)} />
				))}
				<PaddedButton
					label="Add more people"
					variant="secondary"
					onClick={() => setCount((val) => ({ ...val, name: val.name + 1 }))} />
			</div>
			<div className="section">
				<Input
					name="special_message_info"
					icon="description2"
					rules={{ required: true }}
					control={control}
					textarea
					placeholder="Special message"
					maxLength={500} />
			</div>
			<div className="section">
				<p children={lang.REQUEST_SHOUTOUT_EMAIL_PROMPT} />
				{new Array(count.email).fill(null).map((_, i) => (
				<Input
					key={i}
					name={`recipients_emails.${i}`}
					rules={{ required: true }}
					control={control}
					placeholder={`Email #${i + 1}`}
					{...inputIcon('email', i)} />
				))}
				<PaddedButton
					label="Add more emails"
					variant="secondary"
					onClick={() => setCount((val) => ({ ...val, email: val.email + 1 }))} />
			</div>
			<Legend
				label="Total price"
				children={(
					<BigAmount
						amount={athlete.athlete_info.athlete_services.shoutout_rate} />
				)} />
			<Button
				label="Checkout"
				loading={processes}
				variant="primary"
				onClick={handleSubmit(submit)} />
		</>
	);

}
