import React from 'react';
import logo from '../assets/png/logo.png';

export interface LogoProps {
	isBig?: boolean,
	styles?: React.CSSProperties,
}

const size = {
	big: {
		width: 326,
		height: 120,
	},
	small: {
		width: 200,
		height: 73,
	},
} as const;

export const Logo: React.FC<LogoProps> = (props) => {

	const { isBig = false, styles } = props;

	return (
		<div style={styles}>
			<img
				src={logo}
				alt=""
				style={size[isBig ? 'big' : 'small']} />
		</div>
	);

}
