export const SearchParam = {
	encode: (str: string | undefined): string | undefined => {
		if (!str || !str.length) {
			return undefined;
		}
		return str;
	},
	decode: (str: string | (string | null)[] | null | undefined): string | undefined => {
		if (str === null || typeof str !== 'string' || !str.length) {
			return undefined;
		}
		return str;
	},
};
