import { useForm } from 'react-hook-form';

import { lang } from '~/constants';
import { RequestForm } from '~/services';

import {
	Input,
	Button,
	Legend,
	BigAmount
} from '~/components';

export const Endorsement: React.FC<RequestForm.Props> = (props) => {

	const { athlete, onSubmit, processes } = props;

	const { control, handleSubmit, reset } = useForm<RequestForm.Endorsement>();

	const submit = (form: RequestForm.Endorsement) => {

		onSubmit(form, () => reset());

	}

	if (!athlete) {
		return null;
	}

	return (
		<>
			<p children={lang.REQUEST_ENDORSEMENT_TITLE} />
			<Input
				name="task"
				icon="description2"
				rules={{ required: true }}
				control={control}
				textarea
				placeholder="Endorsement task"
				maxLength={500} />
			<Legend
				label="Total price"
				children={(
					<BigAmount
						amount={athlete.athlete_info.athlete_services.endorsement_rate} />
				)} />
			<Button
				label="Checkout"
				loading={processes}
				variant="primary"
				onClick={handleSubmit(submit)} />
		</>
	);

}
