import { ChangeEventHandler, useCallback, useState } from 'react';

import { useData } from '~/store';
import { config, lang } from '~/constants';
import { getVideoDuration } from '~/utils';
import { Button } from '~/components';

import { Legend } from './Legend';
import { VideoWrap } from './VideoWrap';
import { ButtonInput } from './ButtonInput';

interface VideoButtonProps {
	label?: string,
	video?: string | File,
	error?: string,
	noLegend?: boolean,
	limitType?: string[],
	limitSize?: number,
	limitDuration?: number,
	useDefault?: boolean,
	onError?: (error: string) => void,
	onVideoChange?: (file: string | File) => void
}

export const VideoButton: React.FC<VideoButtonProps> = (props) => {

	const {
		label = 'Upload your video',
		video,
		error,
		noLegend,
		limitType = config.VIDEO_TYPE_LIMIT,
		limitSize = config.VIDEO_SIZE_LIMIT,
		limitDuration = config.VIDEO_DURATION_LIMIT,
		useDefault,
		onError,
		onVideoChange
	} = props;

	const [ loading, setLoading ] = useState(false);

	const { intro_video } = useData();

	const onUseDefault = useCallback(
		() => {

			onVideoChange && onVideoChange('default');

		},
		[ onVideoChange ]
	);

	const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
		async ({ target }) => {

			if (!target.files || !target.files[0]) {
				return;
			}

			const file = target.files[0];
			const type = file.type.split('/', 2).pop() || '';
			const size = file.size / 1e+6;

			if (type && !limitType.includes(type)) {
				return onError && onError(lang.VIDEO_TYPE_LIMIT(limitType));
			}

			if (size > limitSize) {
				return onError && onError(lang.VIDEO_SIZE_LIMIT(limitSize));
			}

			setLoading(true);

			const duration = await getVideoDuration(file, true);

			setLoading(false);

			if (duration > limitDuration) {
				return onError && onError(lang.VIDEO_DURATION_LIMIT(limitDuration));
			}

			onVideoChange && onVideoChange(file);

		},
		[
			onError,
			onVideoChange,
			limitType,
			limitSize,
			limitDuration,
		]
	);

	const content = (
		<>
			{video && (
			<VideoWrap
				source={{ uri: video === 'default' ? intro_video : video }} />
			)}
			{useDefault &&
			<Button
				variant="primary"
				onClick={onUseDefault}
				style={{ marginBottom: 20 }}
				label="Upload Cal Legends video"
				type="button" />
			}
			<ButtonInput
				label={`${video && video !== 'default' ? 'Replace' : 'Upload'} ${useDefault ? 'customized personal ' : ''}video`}
				variant="secondary"
				loading={loading}
				onChange={onChange} />
			{!!error && <p className="error" children={error} />}
		</>
	);

	if (noLegend) {
		return content;
	}

	return (
		<Legend
			label={label}
			error={error}
			children={content}
			className="video--legend" />
	);

}
