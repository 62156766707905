import { BigAmount, Legend } from '~/components';

interface RequestEarningsProps {
	amount: number
}

export const RequestEarnings: React.FC<RequestEarningsProps> = (props) => {

	const { amount, children } = props;

	const Content: React.ReactNode[] = [<BigAmount key={0} amount={amount} />];

	if (children) {
		Content.push(children);
	}

	return (
		<Legend
			label="Total earnings from this request">
			{Content}
		</Legend>
	);

}
