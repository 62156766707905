import React from 'react';

import { SquareIcon } from './SquareIcon';

import { IAthlete } from '~/services';
import { mediaLink } from '~/utils';

interface AthleteListedProps {
	athlete: IAthlete,
	onRemove: (athlete: IAthlete) => void
}

export const AthleteListed: React.FC<AthleteListedProps> = (props) => {

	const { athlete, onRemove } = props;

	return (
		<div className="app--athlete-listed">
			<img
				src={mediaLink(athlete.profile_photo, 'S')}
				alt={`${athlete.first_name} ${athlete.last_name}`} />
			<div className="content">
				<p children={`${athlete.first_name}\n${athlete.last_name}`} />
				<p children={`$${athlete.endorsement_rate}`} />
			</div>
			<SquareIcon
				icon={{
					name: 'bin',
				}}
				onClick={() => onRemove(athlete)} />
		</div>
	);

}
