import { mediaLink } from '~/utils';
import { IAccount, IAccountAthlete, isAthlete, isBusiness, _confirm } from '~/services';

import { useAuth } from '../store';
import { AthleteLabels } from './AthleteLabels';
import { AthleteLinks } from './AthleteLinks';
import { SquareIcon } from './SquareIcon';
import { Button } from './Button';

interface AthleteHeaderProps {
	athlete: IAccount | IAccountAthlete,
	profile?: boolean,
	onEdit?: () => void
}

export const AthleteHeader: React.FC<AthleteHeaderProps> = (props) => {

	const { athlete, profile, onEdit } = props;

	const { unAuthorize } = useAuth();

	const { athlete_info: info } = isAthlete(athlete) ?
		athlete : { athlete_info: null };

	const logout = async () => {

		if (!await _confirm.logout()) {
			return;
		}

		unAuthorize();

	}

	return (
		<div className="app--athlete-header">
			<div className="__bg-content">
				<div className="athlete--data">
					<div className="img-container">
						<img
							src={mediaLink(athlete.profile_photo, 'M')}
							alt={`${athlete.first_name} ${athlete.last_name}`} />
					</div>
					<div className="athlete--content">
						<h2>
							<span>{athlete.first_name} {athlete.last_name}</span>
							{!profile && info && (
							<AthleteLabels
								sport={[ info.sports_played, info.sports_played ]} />
							)}
						</h2>
						{!profile && info ? (
						<>
							<AthleteLinks
								account={athlete} />
						</>
						) : (
						<>
							{isBusiness(athlete) ? (
							<a
								rel="noreferrer"
								href={athlete.business_info.website}
								target="_blank"
								className="website"
								children={athlete.business_info.website.replace(/https?:\/\//ig, '')} />
							) : (
							<p className="email">{athlete.email}</p>
							)}
						</>
						)}
					</div>
					{onEdit && (
					<div className="edit-btn">
						<Button
							label="Log out"
							onClick={logout}
							className="logout-btn" />
						<SquareIcon
							icon="pen"
							onClick={onEdit} />
					</div>
					)}
				</div>
			</div>
			<div className="__bg-overlay"></div>
			<div
				style={{ backgroundImage: `url(${mediaLink(athlete.profile_photo, 'M')})` }}
				className="__bg" />
		</div>
	);

}
