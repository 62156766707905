import React from 'react';
import { api } from '../api';
import dayjs from 'dayjs';

import { IAccountAthlete } from '~/services';
import { DeepWritableObject, Option } from '~/types';
import { toTitleCase } from '~/utils';
import { IconProps } from '~/components';
import { IAccount, UserType } from './Account';
import * as RequestForms from '~/containers/RequestForm';

export const REQUEST_STATUS = {
	1: [ 'Pending', 'PENDING' ],
	2: [ 'Accepted', 'ACCEPTED' ],
	3: [ 'Canceled', 'CANCELED' ],
	4: [ 'Pending review', 'PENDING_REVIEW' ],
	5: [ 'Finished', 'FINISHED' ],
	6: [ 'In progress', 'REQUEST_STATUS_IN_PROGRESS' ],
};

export const statusMap: Record<RequestStatus, {
	icon: IconProps['name'],
	color: string,
	priority: number
}> = {
	1: { icon: 'sandClock', color: 'primary', priority: 40, },
	2: { icon: 'sandClock', color: 'primary', priority: 20, },
	3: { icon: 'crossSmall', color: 'white', priority: 10, },
	4: { icon: 'sandClock', color: 'primary', priority: 60, },
	5: { icon: 'flag', color: 'white', priority: 30, },
	6: { icon: 'sandClock', color: 'primary', priority: 45, },
}

export const requestStatusResolve = (status: RequestStatus[]): RequestStatus => {

	let _status: RequestStatus = 1;

	if (status.every((val) => [1, 3].includes(val))) {
		_status = 1;
	} else if (status.includes(4)) {
		_status = 4;
	} else {
		_status = 2;
	}

	if (status.every((val) => [3, 5].includes(val))) {
		_status = 5;
	}

	if (status.every((val) => val === 3)) {
		_status = 3;
	}

	return _status;

}

export const listifyRequest = (
	{ request, profile }: { request: IRequest, profile: IAccount }
): IRequestExtra => {

	const endorsement_campaign_id =
		isRequest.endorsement(request) ?
			request.endorsement.endorsement_campaign_id : '';

	return {
		...request,
		first_name: profile.first_name,
		last_name: profile.last_name,
		profile_photo: profile.profile_photo,
		endorsement_campaign_id,
	};

}

export const findAssociatedProfile = (
	request: IRequest,
	profiles: IAccount[],
	role: UserType
): IAccount => {

	const roleKeys = {
		1: 'athlete_id',
		2: 'requester_id',
		3: 'athlete_id',
	} as Record<
		UserType,
		keyof Pick<IRequest, 'athlete_id' | 'requester_id'>
	>

	const profile = profiles.filter(
		(profile) => profile.id === request[roleKeys[role]]
	)[0];

	return profile;

}

export const getRequestByID = (
	id: string | undefined,
	isCampaign: boolean | undefined,
	requestsById: Record<string, IRequestExtended>
): ViewRequest | null => {

	if (!id) {
		return null;
	}

	if (!isCampaign) {
		return requestsById[id] || null;
	}

	const requests: IRequestEndorsementExtended[] = [];

	for (const _id in requestsById) {
		const request = requestsById[_id];
		if (
			isRequest.endorsement(request) &&
			request.endorsement.endorsement_campaign_id === id
		) {
			requests.push(request);
		}
	}

	if (!requests.length) {
		return null;
	}

	const campaign: ICampaign = {
		id,
		type: 'endorsement_campaign',
		status: requestStatusResolve(
			requests.map(({ status }) => status)
		),
		requests,
		order_number: 0,
		expiration_time: requests[0].expiration_time,
	};

	return campaign;

}

export const REQUEST_TYPE: Record<RequestType, readonly [
	title: string,
	key: string,
	icon: IconProps['name'],
	form: keyof typeof RequestForms,
	boundaries?: { step: number, min: number, max: number }
]> = {
	endorsement_campaign: [
		'Endorsement Campaign',
		'ENDORSEMENT_CAMPAIGN',
		'ServiceEndorsementCampaign',
		'EndorsementCampaign',
	],
	personal_appearance: [
		'Personal appearance',
		'PERSONAL_APPEARANCE',
		'ServiceAppearance',
		'PersonalAppearance',
		{ step: 10, min: 10, max: 1000, }
	],
	speaking_engagement: [
		'Speaking Engagement',
		'SPEAKING_ENGAGEMENT',
		'ServiceSpeakingEngagement',
		'SpeakingEngagement',
		{ step: 10, min: 10, max: 1000, }
	],
	training_session: [
		'Training Session',
		'TRAINING_SESSION',
		'ServiceTrainingSession',
		'TrainingSession',
		{ step: 10, min: 10, max: 1000, }
	],
	endorsement: [
		'Endorsement',
		'ENDORSEMENT',
		'ServiceEndorsement',
		'Endorsement',
		{ step: 10, min: 10, max: 1000, }
	],
	video_chat: [
		'Video chat',
		'VIDEO_CHAT',
		'ServiceVideo',
		'VideoChat',
		{ step: 10, min: 10, max: 1000, }
	],
	shoutout: [
		'Shoutout',
		'SHOUTOUT',
		'ServiceShoutout',
		'Shoutout',
		{ step: 10, min: 10, max: 1000, }
	],
	camp: [
		'Camp',
		'CAMP',
		'ServiceCamp',
		'Camp',
		{ step: 10, min: 10, max: 1000, }
	],
} as const;

export const RequestTypeOptions: Option<string>[] = Object.entries(REQUEST_TYPE)
	.filter(([ value ]) => value !== 'endorsement_campaign')
	.map(
		([ value, [ label ] ]) => ({ value, label })
	);

export type RequestStatus = keyof typeof REQUEST_STATUS;

export type RequestType =
	'endorsement_campaign' |
	'personal_appearance' |
	'speaking_engagement' |
	'training_session' |
	'endorsement' |
	'video_chat' |
	'shoutout' |
	'camp'

export type PaymentType = `payment_${Exclude<RequestType, 'endorsement_campaign'>}`;

export type IRequestExtender = {
	profile: IAccount,
	campaign: string
}

export type IRequestExtended = IRequest & IRequestExtender

export type IRequestEndorsementExtended = TypedRequest.Endorsement & IRequestExtender

export type ViewRequest = IRequestExtended | ICampaign

type RequestActionTypes =
	{ id: string, type: 'CANCEL_CAMPAIGN' } |
	{ id: string, type: 'APPROVE_REQUEST', campaign?: string } |
	{ id: string, type: 'ACCEPT_REQUEST' } |
	{ id: string, type: 'CANCEL_REQUEST' } |
	{ id: string, type: 'DECLINE_REQUEST' } |
	{ id: string, type: 'REVIEW_REQUEST', campaign: string, rate: number } |
	{ id: string, type: 'VIDEO_UPLOAD', request_type: RequestType, file: File } |
	{ id: string, type: 'UPDATE_PAY_STATUS', paid: boolean }

export type ActionsProps = {
	account: IAccount,
	onUpdate: (action: RequestActionTypes) => Promise<unknown>
}

export type RequestActionsProps = ActionsProps & {
	request: IRequestExtended
}

export type CampaignActionsProps = ActionsProps & {
	request: ICampaign
}

export type RequestPageProps = ActionsProps & {
	request: ViewRequest
}

export type RequestTypeMap = {
	endorsement_campaign: ICampaign,
	personal_appearance: TypedRequest.PersonalAppearance,
	speaking_engagement: TypedRequest.SpeakingEngagement,
	training_session: TypedRequest.TrainingSession,
	endorsement: TypedRequest.Endorsement,
	video_chat: TypedRequest.VideoChat,
	shoutout: TypedRequest.Shoutout,
	camp: TypedRequest.Camp
}

export type RequestViewProps<T extends RequestType> = ActionsProps & {
	request: RequestTypeMap[T] & IRequestExtender
}

export type RequestViewComponent<T extends RequestType> = React.FC<
	RequestViewProps<T>
>

export type AppearanceLocationType = {
	readonly id: string,
	readonly request_id: string,
	address: string,
	latitude: number,
	longitude: number
}

export namespace RequestScheme {
	export interface General {
		readonly request_id: string,
		readonly created_at: number,
		readonly updated_at: number
	}
	export interface Camp extends General {
		readonly end_time: number,
		start_time: number,
		location: AppearanceLocationType,
		duration: number,
		event_description: string,
		special_conditions: string
	}
	export interface Shoutout extends General {
		readonly video: string,
		names_in_shoutout: string,
		recipients_emails: string,
		special_message_info: string
	}
	export interface VideoChat extends General {
		readonly ready: boolean,
		readonly app_id: string,
		readonly fan_uid: number,
		readonly fan_token: string,
		readonly athlete_uid: number,
		readonly athlete_token: string,
		readonly channel_name: string,
		readonly token_expiration_time: number,
		date_time: number,
		event_description: string
	}
	export interface Endorsement extends General {
		readonly endorsement_campaign_id: string,
		readonly video: string,
		readonly link: string,
		task: string
	}
	export interface TrainingSession extends General {
		readonly end_time: number,
		start_time: number,
		location: AppearanceLocationType,
		duration: number,
		description: string,
		special_conditions: string,
	}
	export interface SpeakingEngagement extends General {
		readonly end_time: number,
		start_time: number,
		location: AppearanceLocationType,
		topics: string,
		special_conditions: string,
		special_message: string,
	}
	export interface PersonalAppearance extends General {
		location: AppearanceLocationType,
		start_time: number,
		readonly end_time: number,
		event_description: string
	}
	export interface InputGeneral {
		athlete_id: string
	}
	export interface CampInput extends InputGeneral {
		camp: DeepWritableObject<Camp>
	}
	export interface ShoutoutInput extends InputGeneral {
		shoutout: DeepWritableObject<Shoutout>
	}
	export interface VideoChatInput extends InputGeneral {
		video_chat: DeepWritableObject<VideoChat>
	}
	export interface EndorsementInput extends InputGeneral {
		endorsement: DeepWritableObject<Endorsement>
	}
	export interface TrainingSessionInput extends InputGeneral {
		training_session: DeepWritableObject<TrainingSession>
	}
	export interface SpeakingEngagementInput extends InputGeneral {
		speaking_engagement: DeepWritableObject<SpeakingEngagement>
	}
	export interface PersonalAppearanceInput extends InputGeneral {
		personal_appearance: DeepWritableObject<PersonalAppearance>
	}
	export interface EndorsementCampaignInput {
		endorsement_task: string,
		endorsement_link: string,
		athlete_ids: string
	}
	export type Input =
		CampInput |
		ShoutoutInput |
		VideoChatInput |
		EndorsementInput |
		TrainingSessionInput |
		SpeakingEngagementInput |
		PersonalAppearanceInput |
		SpeakingEngagementInput |
		EndorsementCampaignInput
}

export namespace RequestForm {

	export interface Props {
		athlete: IAccountAthlete | null,
		onSubmit: (form: Scheme, reset: () => void) => void,
		onUpdate?: (form: Scheme) => void,
		processes: boolean
	}

	export interface Camp {
		location: DeepWritableObject<AppearanceLocationType>,
		duration: number,
		start_time: Date,
		event_description: string,
		special_conditions: string
	}

	export interface Shoutout {
		names_in_shoutout: string[],
		recipients_emails: string[],
		special_message_info: string
	}

	export interface Endorsement {
		task: string
	}

	export interface VideoChat {
		date: Date,
		event_description: string
	}

	export interface TrainingSession {
		location: DeepWritableObject<AppearanceLocationType>,
		duration: number,
		start_time: Date,
		description: string,
		special_conditions: string
	}

	export interface SpeakingEngagement {
		location: DeepWritableObject<AppearanceLocationType>,
		start_time: Date,
		topics: string,
		special_message: string
		special_conditions: string
	}

	export interface PersonalAppearance {
		location: DeepWritableObject<AppearanceLocationType>
		date: Date,
		event_description: string
	}

	export interface EndorsementCampaign {
		endorsement_task: string,
		endorsement_link: string,
		athlete_ids: string[]
	}

	export type Scheme =
		Camp |
		Shoutout |
		VideoChat |
		Endorsement |
		TrainingSession |
		SpeakingEngagement |
		PersonalAppearance |
		EndorsementCampaign

}

export interface IPayment {
	id: string,
	type: PaymentType,
	amount: number,
	request_id: string,
	sender: string,
	sender_name: string,
	athlete_id: string,
	athlete_name: string,
	stripe_charge_id: string,
	payout_status: boolean,
	created_at: number,
	updated_at: number,
	created_by: string,
	updated_by: string
}

export interface ICampaign {
	id: string,
	type: RequestType
	status: RequestStatus,
	order_number: 0,
	expiration_time: number,
	requests: (TypedRequest.Endorsement & IRequestExtender)[]
}

export interface IRequest {
	id: string,
	type: RequestType,
	paid: boolean,
	authorised: boolean,
	status: RequestStatus,
	payment: IPayment | null,
	is_apple_iap: boolean,
	reviewed: boolean,
	athlete_id: string,
	updated_at: number
	created_at: number,
	accepted_at: number,
	requester_id: string,
	order_number: number,
	request_amount: number,
	expiration_time: number,
	personal_appearance: RequestScheme.PersonalAppearance | null,
	speaking_engagement: RequestScheme.SpeakingEngagement | null,
	training_session: RequestScheme.TrainingSession | null,
	endorsement: RequestScheme.Endorsement | null,
	video_chat: RequestScheme.VideoChat | null,
	shoutout: RequestScheme.Shoutout | null
	camp: RequestScheme.Camp | null
}

export namespace TypedRequest {
	export interface PersonalAppearance extends IRequest {
		type: 'personal_appearance',
		personal_appearance: RequestScheme.PersonalAppearance
	}
	export interface SpeakingEngagement extends IRequest {
		type: 'speaking_engagement',
		speaking_engagement: RequestScheme.SpeakingEngagement
	}
	export interface TrainingSession extends IRequest {
		type: 'training_session',
		training_session: RequestScheme.TrainingSession
	}
	export interface Endorsement extends IRequest {
		type: 'endorsement',
		endorsement: RequestScheme.Endorsement
	}
	export interface VideoChat extends IRequest {
		type: 'video_chat',
		video_chat: RequestScheme.VideoChat
	}
	export interface Shoutout extends IRequest {
		type: 'shoutout',
		shoutout: RequestScheme.Shoutout
	}
	export interface Camp extends IRequest {
		type: 'camp',
		camp: RequestScheme.Camp
	}
}

export const isRequest = {
	personalAppearance (val: IRequest | ICampaign): val is TypedRequest.PersonalAppearance {
		return 'personal_appearance' in val && val.type === 'personal_appearance';
	},
	speakingEngagement (val: IRequest | ICampaign): val is TypedRequest.SpeakingEngagement {
		return 'speaking_engagement' in val && val.type === 'speaking_engagement';
	},
	trainingSession (val: IRequest | ICampaign): val is TypedRequest.TrainingSession {
		return 'training_session' in val && val.type === 'training_session';
	},
	endorsement (val: IRequest | ICampaign): val is TypedRequest.Endorsement {
		return 'endorsement' in val && val.type === 'endorsement';
	},
	videoChat (val: IRequest | ICampaign): val is TypedRequest.VideoChat {
		return 'video_chat' in val && val.type === 'video_chat';
	},
	shoutout (val: IRequest | ICampaign): val is TypedRequest.Shoutout {
		return 'shoutout' in val && val.type === 'shoutout';
	},
	camp (val: IRequest | ICampaign): val is TypedRequest.Camp {
		return 'camp' in val && val.type === 'camp';
	},
	withVideo (val: IRequest | ICampaign): val is TypedRequest.Shoutout | TypedRequest.Endorsement {
		return ('shoutout' in val || 'endorsement' in val) &&
			(val.type === 'shoutout' || val.type === 'endorsement');
	},
	withTimestamp (val: IRequest | ICampaign): val is
		TypedRequest.Camp |
		TypedRequest.VideoChat |
		TypedRequest.TrainingSession |
		TypedRequest.PersonalAppearance |
		TypedRequest.SpeakingEngagement {
		const entity = (
			'camp' in val ||
			'video_chat' in val ||
			'training_session' in val ||
			'personal_appearance' in val ||
			'speaking_engagement' in val
		);
		return entity &&
			(val.type === 'camp' ||
			val.type === 'video_chat' ||
			val.type === 'training_session' ||
			val.type === 'personal_appearance' ||
			val.type === 'speaking_engagement');
	},
	endorsementCampaign (val: IRequest | ICampaign): val is ICampaign {
		return 'requests' in val;
	},
};

export const getRequestTitle = (request: IRequest | ICampaign, nl?: boolean) => {

	if (isRequest.endorsementCampaign(request)) {
		return `Endorsement${nl ? `\n` : ''} Campaign`;
	}

	const { type, order_number } = request;

	return `${toTitleCase(REQUEST_TYPE[type][0])}${nl ? `\n` : ''} Request${order_number ? ` #${order_number}` : ''}`;

}

export interface IRequestExtra extends IRequest {
	first_name: string,
	last_name: string,
	profile_photo: string,
	endorsement_campaign_id: string
}

export interface RequestCounters {
	counters: {
		last_month: number,
		last_week: number,
		total_personal_appearance: number,
		total_shoutout: number,
		total_camps: number,
		total_training_sessions: number,
		total_speaking_engagements: number,
		total_video_chat: number,
		total_endorsement: number,
		pending_personal_appearance: number,
		pending_shoutout: number,
		pending_camps: number,
		pending_training_sessions: number,
		pending_speaking_engagements: number,
		pending_video_chat: number,
		pending_endorsement: number,
	},
	week_amount: number,
	month_amount: number,
	total_amount: number
}

export const requestInputTransform = (
	athlete_id: string,
	type: RequestType,
	form: RequestForm.Scheme
): RequestScheme.Input => {

	switch (type) {
		case 'personal_appearance': {
			const _form = form as RequestForm.PersonalAppearance;
			return {
				athlete_id,
				personal_appearance: {
					..._form,
					start_time: dayjs(_form.date).unix(),
				},
			};
		}
		case 'speaking_engagement': {
			const _form = form as RequestForm.SpeakingEngagement;
			return {
				athlete_id,
				speaking_engagement: {
					..._form,
					start_time: dayjs(_form.start_time).unix(),
				},
			};
		}
		case 'training_session': {
			const _form = form as RequestForm.TrainingSession;
			return {
				athlete_id,
				training_session: {
					..._form,
					start_time: dayjs(_form.start_time).unix(),
				},
			};
		}
		case 'endorsement': {
			const _form = form as RequestForm.Endorsement;
			return {
				athlete_id,
				endorsement: {
					..._form,
				},
			};
		}
		case 'video_chat': {
			const _form = form as RequestForm.VideoChat;
			return {
				athlete_id,
				video_chat: {
					..._form,
					date_time: dayjs(_form.date).unix(),
				},
			};
		}
		case 'shoutout': {
			const _form = form as RequestForm.Shoutout;
			return {
				athlete_id,
				shoutout: {
					..._form,
					names_in_shoutout: _form.names_in_shoutout.join(', '),
					recipients_emails: _form.recipients_emails.join(', '),
				},
			};
		}
		case 'camp': {
			const _form = form as RequestForm.Camp;
			return {
				athlete_id,
				camp: {
					..._form,
					start_time: dayjs(_form.start_time).unix(),
				},
			};
		}
		case 'endorsement_campaign': {
			const _form = form as RequestForm.EndorsementCampaign;
			return {
				..._form,
				athlete_ids: _form.athlete_ids.join(','),
			};
		}
	}

}

const path = '/booking/request';

export type RequestCreateOutput = {
	request: IRequest,
	account: IAccount
} | {
	requests: IRequest[],
	accounts: IAccount[],
	endorsement_campaign_id: string
}

export type RequestReadOutput = {
	request: IRequest,
	account: IAccount
} | {
	requests: TypedRequest.Endorsement[],
	accounts: IAccount[]
}

export const Request = {

	read: () => api.get<{
		requests: IRequestExtra[],
	}>(`${path}/filter`),

	readCurrent: (
		id: string,
		isCampaign?: boolean
	) => api.get<RequestReadOutput>(
		`${path}${isCampaign ? '/campaign?endorsement_campaign' : '?id'}=${id}`
	),

	create: (
		data: RequestScheme.Input,
		type: RequestType
	) => api.post<RequestCreateOutput>(
		`${path}${type === 'endorsement_campaign' ? '/endorsement_campaign/create' : ''}`,
		data,
		{ type: type === 'endorsement_campaign' ? 'FORM' : 'JSON' }
	),

	accept: (id: string) => api.put(`${path}/accept?request_id=${id}`),

	decline: (id: string) => api.put(`${path}/decline?request_id=${id}`),

	cancel: (id: string) => api.put(`${path}/cancel?request_id=${id}`),

	approve: (data: { request_id: string }) => api.put(`${path}/approve`, data, { type: 'DATA' }),

	review: (data: { request_id: string, rate: number }) => api.put('/booking/reviews', data),

	uploadVideo: (data: {
		file: File,
		type: RequestType,
		request_id: string,
	}) => api.post<{
		path: string
	}>(`/athlete/${data.type}s`, data, { type: 'DATA' }),

	finishVideoChat: (data: { request_id: string }) => api.put(`${path}/complete-video-chat`, data, { type: 'DATA' }),

	isVideoChatFinished: (request_id: string) => api.get<{
		video_chat_completed: boolean
	}>(`${path}/complete-video-chat-check?request_id=${request_id}`),

	getStats: () => api.get<RequestCounters>(`${path}/counters`),

	cancelCampaign: (id: string) => api.put(`${path}/campaign/cancel?campaign_id=${id}`),

};
