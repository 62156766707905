type TeamType = {
	name: string,
	text: [ string | string[], number | number[], string ]
}

export const teams: Record<string, TeamType> = {
	'5fc39903-d315-4841-80f9-5cd205532c5b': {
		name: 'Boston College Eagles',
		text: [ ['Boston College\n', 'Eagles'], [20, 14], 'BCE' ],
	},
	'286ef188-76f0-4fd1-80d6-7f8e62d47235': {
		name: 'Clemson Tigers',
		text: [ ['Clemson\n', 'Tigers'], [20, 20], 'CT' ],
	},
	'6b653d18-a959-4467-8555-65548929a00a': {
		name: 'Duke Bule Devils',
		text: [ ['Duke Bule\n', 'Devils'], [20, 20], 'DBD' ],
	},
	'227c7ad5-954d-4c86-b6ce-fde48fbd0342': {
		name: 'Florida State Seminoles',
		text: [ ['Florida State\n', 'Seminoles'], [20, 20], 'FSS' ],
	},
	'185cf933-3221-4292-9766-0bd2f859845f': {
		name: 'Georgia Tech Yellow Jackets',
		text: [ ['Georgia Tech\n', 'Yellow Jackets'], [14, 14], 'GTYJ' ],
	},
	'a3bd52f3-8773-4eb4-8c63-e26a6e71c754': {
		name: 'Louisville Cardinals',
		text: [ ['Louisville\n', 'Cardinals'], [20, 20], 'LC' ],
	},
	'e747677a-bc8b-448e-b8c7-abd57bfc0cc0': {
		name: 'Miami Hurricanes',
		text: [ ['Miami\n', 'Hurricanes'], [20, 18], 'MH' ],
	},
	'0c4faaf9-956c-47dc-a662-0c5f5b3ccf3a': {
		name: 'NC State Wolfpack',
		text: [ ['NC\n', 'State Wolfpack'], [34, 14], 'NCSW' ],
	},
	'eff91056-82ea-456f-abbc-201930651e0d': {
		name: 'North Carolina Tar Heels',
		text: [ ['North Carolina\n', 'Tar Heels'], [20, 20], 'NCTH' ],
	},
	'afa23752-81af-4076-9388-074b8d94b7b7': {
		name: 'Pittsburgh Panthers',
		text: [ ['Pittsburgh\n', 'Panthers'], [20, 20], 'PP' ],
	},
	'1928f9a0-2dbd-4ee3-baec-7ccf8de148dd': {
		name: 'Syracuse Orange',
		text: [ ['Syracuse\n', 'Orange'], [20, 20], 'SO' ],
	},
	'574eb2da-a25b-446d-9e56-a03d9b9e0be2': {
		name: 'Virginia Cavaliers',
		text: [ ['Virginia\n', 'Cavaliers'], [20, 20], 'VC' ],
	},
	'94fc0cdf-7b86-4962-ad1f-cee9be1b3fc0': {
		name: 'Virginia Tech Hokies',
		text: [ ['Virginia\n', 'Tech Hokies'], [20, 20], 'VTH' ],
	},
	'4bdcf5cc-e559-4096-9a03-0e5e50c7fe9e': {
		name: 'Wake Forest Demon Deacons',
		text: [ ['Wake Forest\n', 'Demon Deacons'], [20, 20], 'WFDD' ],
	},
};

type ConferenceType = {
	name: string,
	text: [ string | string[], number | number[], string ]
}

export const conferences: Record<string, ConferenceType> = {
	'34977e6c-5b70-400b-b583-8c4474deec92': {
		name: 'American Athletic Conference',
		text: [ ['AA\n', 'Conference'], [34, 14], 'AA' ],
	},
	'183ed5a8-0af5-4c4a-9952-d9623afdf5c0': {
		name: 'Big Ten Conference',
		text: [ ['BT\n', 'Conference'], [34, 14], 'BT' ],
	},
	'73b9d027-db31-4f42-a92c-4ea1a5efc96a': {
		name: 'Atlantic Coast Conference',
		text: [ ['AC\n', 'Conference'], [34, 14], 'AC' ],
	},
	'dd4191f4-5918-4886-ae6e-8f9ef39ee9ac': {
		name: 'Big 12 Conference',
		text: [ ['B12\n', 'Conference'], [24, 14], 'B12' ],
	},
	'a3d81401-fba6-4ee7-bf80-9f62a7b35dae': {
		name: 'Conference USA',
		text: [ ['Conference\n', 'USA'], [14, 24], 'USA' ],
	},
	'963773c7-81dc-48b5-8371-064d2bda0412': {
		name: 'Division 1 FBS',
		text: [ 'D1\n FBS', 24, 'D1' ],
	},
	'8bb0bc47-50d7-4b07-a556-41dda3d419b5': {
		name: 'Independents Mid-American Conference',
		text: [ ['IMID-A\n', 'Conference'], [24, 14], 'IMID' ],
	},
	'020fcda7-ec1e-4c3c-b2a1-d1c6c9df1cb3': {
		name: 'Mountain West Conference',
		text: [ ['MW\n', 'Conference'], [24, 14], 'MW' ],
	},
	'f2080798-1b86-4a93-bff4-f6202108cdfc': {
		name: 'Pac-12 Conference',
		text: [ ['PAC-12\n', 'Conference'], [20, 14], 'PAC' ],
	},
	'5dfc3e26-131b-4e4b-9901-52de2d6c5e17': {
		name: 'SEC',
		text: [ 'SEC', 34, 'SEC' ],
	},
	'5abefc56-71d4-4cf1-8cab-49a698cfe1cd': {
		name: 'Sun Belt Conference',
		text: [ ['SB\n', 'Conference'], [34, 14], 'SB' ],
	},
	'e6752156-cb91-4f19-8143-4c26d7ee0125': {
		name: 'Division 2',
		text: [ 'D2', 34, 'D2' ],
	},
	'd33923e5-aba6-4190-b33e-baaba1cc5a61': {
		name: 'Division 3',
		text: [ 'D3', 34, 'D3' ],
	},
	'1394253c-3e0e-4fa2-8e85-7ce9937e411b': {
		name: 'NAIA',
		text: [ 'NAIA', 28, 'NAIA' ],
	},
	'27887a6e-eda2-44e4-947c-5f87f7b4487a': {
		name: 'Junior Colleges',
		text: [ 'Junior\n Colleges', 14, 'JC' ],
	},
};

export const sports: Record<string, string> = {
	'5c8afee4-1029-4805-9135-15f4604ad412': 'Football',
	'15279806-dfc0-4263-b6e0-6946b64825ec': 'Basketball',
	'64aab0b8-9378-408e-bb36-54da402ccb56': 'Baseball',
	'b910384b-a464-4146-8e74-0a0f08a23c42': 'Softball',
	'34dfdb77-1409-42ef-8478-a7e042bea1c9': 'Volleyball',
	'6d93792b-a962-4b39-886e-b735158dfcb9': 'Soccer',
	'27136548-b623-4143-9df9-794ccab4b985': 'Lacrosse',
	'd1b4e07f-2d6d-4fc6-80b9-dbebde65b4d1': 'Tennis',
	'e3108e3b-4bbd-4854-a320-c02df95ddd32': 'Hockey',
	'5e2e2129-bf31-41e8-8072-83bdb237af20': 'Wrestling',
	'50d04b4e-3214-4f25-ba75-0f9f0f7e7f8e': 'Gymnastics',
	'f422fbc8-3337-4e31-91fe-e391cb024661': 'Track & Field',
	'328f237c-e760-4411-8337-2c2e77f2a7df': 'Field Hockey',
	'9e45d836-7c00-4666-9a3b-f496b2519410': 'Swimming',
	'2e1885ae-1113-46ce-bd80-09e375d168a1': 'Crew',
	'be680abe-3370-4afd-a39f-49028c3e7534': 'Golf',
};
