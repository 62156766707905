import { useCallback, useMemo, useState } from 'react';

import { useAuth, useData } from '~/store';
import { PartialRecord } from '~/types';

import { StepProps } from '~/services/signup'

import {
	Account,
	Athlete,
	handleError,
	parseProducts,
	RequestType,
	REQUEST_TYPE,
	ServiceType,
	SignUpAthleteInput
} from '~/services';

import {
	Button,
	Collapsable,
	Legend,
	MapLocation,
	MapWrap,
	QuantitySelector
} from '~/components';;

export const SignUpServices: React.FC<StepProps> = (props) => {

	const {
		data: [ data, setData ],
		account
	} = props;

	const { authorize } = useAuth();

	const [ loading, setLoading ] = useState(false);

	const { locations, appearance, services } = data;

	const { iap_apple_products } = useData();

	const {
		personal_appearance,
		speaking_engagement,
		training_session,
		endorsement,
		video_chat,
		shoutout,
		camp
	} = services;

	const [ map, setMap ] = useState<RequestType | undefined>();

	const toggleService = useCallback((key: ServiceType) => {

		setData((data) => ({
			...data,
			services: {
				...data.services,
				[key]: {
					...data.services[key],
					enabled: !data.services[key].enabled
				},
			},
		}));

	}, [ setData ]);

	const changeRate = useCallback((key: ServiceType, rate: number) => {

		setData((data) => ({
			...data,
			services: {
				...data.services,
				[key]: {
					...data.services[key],
					rate,
				},
			},
		}));

	}, [ setData ]);

	const isValid = useMemo(
		() => {
			return Object.values(services)
				.map(({ enabled }) => enabled)
				.includes(true)
		},
		[ services ]
	);

	const submit = async () => {

		if (
			!account ||
			!data.sport ||
			!data.school
		) {
			return;
		}

		const appearance_type = Athlete.stringifyAppearances(appearance);

		const athlete_locations = locations.map(
			(val) => ({ ...val, athlete_id: account.id })
		);

		const input: SignUpAthleteInput = {
			athlete_personal_info: {
				last_name: data.last_name,
				first_name: data.first_name,
				description: data.description,
				intro_video: data.video,
				profile_photo: data.profile_photo,
				...data.links,
			},
			athlete_info: {
				athlete_id: account.id,
				school_id: '',
				school_name: '',
				school_email: '',
				sports_played: data.sport[0].id,
				athlete_services: {
					athlete_id: account.id,
					appearance_type,
					athlete_locations,
					speaking_engagement_enabled: speaking_engagement.enabled,
					speaking_engagement_rate: speaking_engagement.rate,
					training_session_enabled: training_session.enabled,
					training_session_rate: training_session.rate,
					personal_appearance_enabled: personal_appearance.enabled,
					personal_appearance_rate: personal_appearance.rate,
					endorsement_enabled: endorsement.enabled,
					endorsement_rate: endorsement.rate,
					video_chat_enabled: video_chat.enabled,
					video_chat_rate: video_chat.rate,
					shoutout_enabled: shoutout.enabled,
					shoutout_rate: shoutout.rate,
					camp_enabled: camp.enabled,
					camp_rate: camp.rate,
				},
			},
		};

		try {

			setLoading(true);

			const { account } = await Account.signUpAthlete(input).promise;

			authorize({ account });

		} catch (e) {

			setLoading(false);

			handleError(e);

		}

	}

	const Locations = useMemo(
		() => {

			const _locations: PartialRecord<RequestType, JSX.Element[]> = {};

			locations.forEach((item, i) => {

				if (!_locations[item.location_type]) {
					_locations[item.location_type] = [];
				}

				_locations[item.location_type]?.push(
					<MapLocation
						{...item}
						key={i}
						onRemove={() => {

							const _locations = [ ...locations ];

							_locations.splice(i, 1);

							setData((data) => ({ ...data, locations: _locations }));

						}} />
				);

			});

			return _locations;

		},
		[ locations, setData ]
	);

	return (
		<>
			<Legend
				label="Services Offered">
				<Collapsable
					title="In person appearance"
					selected={personal_appearance.enabled}
					setSelected={() => toggleService('personal_appearance')}>
					<QuantitySelector
						{...REQUEST_TYPE.personal_appearance[4]}
						prefix="$"
						amount={personal_appearance.rate}
						onChange={(val) => changeRate('personal_appearance', val)} />
					<Legend
						label="Travel Parameters">
						{Locations.personal_appearance}
						<Button
							variant="secondary"
							onClick={() => setMap('personal_appearance')}
							label={`Add${Locations.personal_appearance?.length ? ' more' : ''} locations`} />
					</Legend>
				</Collapsable>
				<Collapsable
					title="Shoutouts"
					selected={shoutout.enabled}
					setSelected={() => toggleService('shoutout')}>
					<QuantitySelector
						{...REQUEST_TYPE.shoutout[4]}
						prefix="$"
						array={parseProducts(iap_apple_products).shoutout?.amount.usd || []}
						amount={shoutout.rate}
						onChange={(val) => changeRate('shoutout', val)} />
				</Collapsable>
				<Collapsable
					title="Video Chats"
					selected={video_chat.enabled}
					setSelected={() => toggleService('video_chat')}>
					<QuantitySelector
						{...REQUEST_TYPE.video_chat[4]}
						prefix="$"
						amount={video_chat.rate}
						onChange={(val) => changeRate('video_chat', val)} />
				</Collapsable>
				<Collapsable
					title="Endorsement"
					selected={endorsement.enabled}
					setSelected={() => toggleService('endorsement')}>
					<QuantitySelector
						{...REQUEST_TYPE.endorsement[4]}
						prefix="$"
						amount={endorsement.rate}
						onChange={(val) => changeRate('endorsement', val)} />
				</Collapsable>
				<Collapsable
					title="Camp"
					selected={camp.enabled}
					setSelected={() => toggleService('camp')}>
					<QuantitySelector
						{...REQUEST_TYPE.camp[4]}
						prefix="$"
						amount={camp.rate}
						label="per hour"
						onChange={(val) => changeRate('camp', val)} />
					<Legend
						label="Travel Parameters">
						{Locations.camp}
						<Button
							variant="secondary"
							onClick={() => setMap('camp')}
							label={`Add${Locations.camp?.length ? ' more' : ''} locations`} />
					</Legend>
				</Collapsable>
				<Collapsable
					title="Training Session"
					selected={training_session.enabled}
					setSelected={() => toggleService('training_session')}>
					<QuantitySelector
						{...REQUEST_TYPE.training_session[4]}
						prefix="$"
						label="per hour"
						amount={training_session.rate}
						onChange={(val) => changeRate('training_session', val)} />
					<Legend
						label="Travel Parameters">
						{Locations.training_session}
						<Button
							variant="secondary"
							onClick={() => setMap('training_session')}
							label={`Add${Locations.training_session?.length ? ' more' : ''} locations`} />
					</Legend>
				</Collapsable>
				<Collapsable
					title="Speaking Engagements"
					selected={speaking_engagement.enabled}
					setSelected={() => toggleService('speaking_engagement')}>
					<QuantitySelector
						{...REQUEST_TYPE.speaking_engagement[4]}
						prefix="$"
						amount={speaking_engagement.rate}
						onChange={(val) => changeRate('speaking_engagement', val)} />
					<Legend
						label="Travel Parameters">
						{Locations.speaking_engagement}
						<Button
							variant="secondary"
							onClick={() => setMap('speaking_engagement')}
							label={`Add${Locations.speaking_engagement?.length ? ' more' : ''} locations`} />
					</Legend>
				</Collapsable>
			</Legend>
			<Button
				label="Save services"
				onClick={submit}
				variant="primary"
				loading={loading}
				disabled={!isValid}
				disabledDeep />
			<p
				className="message"
				children="You have to choose at least one type of service" />
			<MapWrap
				onClose={() => setMap(undefined)}
				isVisible={!!map}
				locationType={map}
				onLocationCreate={(location) => {
					setData((data) => ({
						...data,
						locations: [ ...data.locations, location ]
					}));
					setMap(undefined);
				}} />
		</>
	);

}
