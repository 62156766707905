import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import cn from 'classnames';

import { useAuth } from '~/store';
import { Athlete, handleError, IAthlete, isBusiness } from '~/services';
import { AthleteCard, Button, Search, Skeleton } from '~/components';
import { PageContent, PageTitle } from '~/containers';
import { CampaignPrompt } from '../containers/Modals';

export const Dashboard: React.FC = () => {

	const { account } = useAuth();

	const [ search, setSearch ] = useState('');

	const { push } = useHistory();

	const [ filter ] = useDebounce(search, 400);

	const [ loading, setLoading ] = useState(0);

	const [ athletes, setAthletes ] = useState<IAthlete[]>([]);

	const [ campaignPrompt, setCampaignPrompt ] = useState<boolean>(false);

	const retrieve = useCallback(async () => {

		try {

			setLoading(filter ? 2 : 1);

			const { athletes } = await Athlete.read({ filter }).promise;

			setAthletes([]);

			setAthletes(athletes);

		} catch (e) {

			handleError(e);

		} finally {

			setLoading(0);

		}

	}, [ filter ]);

	useEffect(() => {

		retrieve();

	}, [ retrieve ]);

	const PageHeader = (
		<>
			<Search
				loading={loading === 2}
				onReset={() => setSearch('')}
				onSearch={setSearch}
				placeholder="School name, athlete name or sport" />
			{isBusiness(account) && (
			<>
				<Button
					label="Create campaign"
					variant="primary"
					onClick={() => setCampaignPrompt(true)} />
			</>
			)}
		</>
	);

	const EmptyContent = (
		<p className="content--empty">Athletes not found</p>
	);

	return (
		<>
			<PageTitle title="Dashboard" />
			<PageContent
				data={athletes}
				loading={loading === 1}
				loadingComponent={<Skeleton />}
				renderItem={(athlete) => (
					<AthleteCard
						key={athlete.id}
						athlete={athlete}
						onClick={() => push(`/@${athlete.username}`)} />
				)}
				pageContentHeader={PageHeader}
				pageContentHeaderClassName={cn('content-search', { business: !!isBusiness(account) })}
				pageContentClassName="grid-3s"
				pageEmptyComponent={EmptyContent}>
			</PageContent>
			<CampaignPrompt
				isVisible={campaignPrompt}
				setVisile={setCampaignPrompt} />
		</>
	);

}
