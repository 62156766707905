import dayjs from 'dayjs';

import { RequestViewComponent as RWC } from '~/services';
import { Legend, OptionView } from '~/components';

import { RequestEarnings, RequestPerson } from '../Request';

export const VideoChat: RWC<'video_chat'> = (props) => {

	const { request: { profile, ...request } } = props;

	const { video_chat, request_amount } = request;

	const { date_time } = video_chat;

	const time = dayjs(date_time * 1000);

	return (
		<>
			<RequestPerson
				profile={profile}
				request={request} />
			<Legend
				label="Event description"
				children={video_chat.event_description} />
			<RequestEarnings
				amount={request_amount}>
				<OptionView
					label="Date"
					value={time.format('MMMM, D YYYY')} />
				<OptionView
					label="Time"
					value={`${time.format('h:mm a')}`} />
			</RequestEarnings>
		</>
	);

}
