import dayjs from 'dayjs';

import { RequestViewComponent as RWC } from '~/services';
import { Legend, OptionView } from '~/components';
import { pluralize } from '~/utils';

import { RequestPerson, RequestEarnings } from '../Request';

export const TrainingSession: RWC<'training_session'> = (props) => {

	const { request: { profile, ...request } } = props;

	const { training_session } = request;

	const time = dayjs(training_session.start_time * 1000);
	const timeEnd = dayjs(training_session.end_time * 1000);

	return (
		<>
			<RequestPerson
				profile={profile}
				request={request} />
			<Legend
				label="Event description"
				children={training_session.description} />
			<Legend
				label="Special conditions"
				children={training_session.special_conditions} />
			<RequestEarnings
				amount={request.request_amount}>
				<OptionView
					label="Location"
					value={training_session.location.address} />
				<OptionView
					label="Duration"
					value={pluralize(training_session.duration, 'hour')} />
				<OptionView
					label="Date"
					value={time.format('MMMM, D YYYY')} />
				<OptionView
					label="Time"
					value={`${time.format('h:mm a')} - ${timeEnd.format('h:mm a')}`} />
			</RequestEarnings>
		</>
	);

}
