import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { lang } from '~/constants';
import { pushOrRemove } from '~/utils';

import { IAthlete, RequestForm } from '~/services';
import { AthleteListed, Button, Input, Legend } from '~/components';

import { athletesCount, AthletesPicker, athletesSummary } from '../AthletesPicker';
import { PageTitle } from '../Page';

export const EndorsementCampaign: React.FC<RequestForm.Props> = (props) => {

	const { onSubmit, onUpdate, processes } = props;

	const { goBack } = useHistory();

	const {
		control,
		reset,
		watch,
		getValues,
		handleSubmit
	} = useForm<RequestForm.EndorsementCampaign>();

	const [ athletes, setAthletes ] = useState<IAthlete[]>([]);

	const [ picker, setPicker ] = useState(true);

	const summary = useMemo(
		() => athletesSummary(athletes),
		[ athletes ]
	);

	useEffect(() => {

		onUpdate && onUpdate({
			...getValues(),
			athlete_ids: athletes.map(({ id }) => id)
		});

		const sub = watch(
			(form) => onUpdate && onUpdate({
				...form,
				athlete_ids: athletes.map(({ id }) => id)
			})
		);

		return () => sub.unsubscribe();

	// eslint-disable-next-line
	}, [ athletes ]);

	const submit = (form: RequestForm.EndorsementCampaign) => {

		if (!athletes.length) {
			return;
		}

		onSubmit({
			...form,
			athlete_ids: athletes.map(({ id }) => id),
		}, () => {
			reset();
			setAthletes([]);
		});

	}

	if (picker) {
		return (
			<AthletesPicker
				list={[ athletes, setAthletes ]}
				shown={picker}
				onHide={() => setPicker(false)} />
		);
	}

	return (
		<>
			<PageTitle
				title="Endorsement Campaign"
				onBack={goBack} />
			<div className="form--inner">
				<p
					className="message"
					children={lang.CAMPAIGN_FORM_TEXT} />
				<Input
					name="endorsement_task"
					icon="info"
					style={{ minHeight: 100 }}
					rules={{ required: true, maxLength: 500 }}
					maxLength={500}
					control={control}
					textarea
					placeholder="Endorsement task" />
				<Input
					name="endorsement_link"
					icon="link"
					control={control}
					placeholder="Endorsement link" />
				<Legend
					label={lang.SERVICES_PRICING}>
					{!!athletes.length && athletes.map((athlete, i) => (
					<AthleteListed
						key={i}
						athlete={athlete}
						onRemove={(athlete) => setAthletes((val) => pushOrRemove(val, athlete, 'id'))} />
					))}
					<div className="legend-extender">
						<Button
							variant="secondary"
							disabled={processes}
							label={`Add ${athletes.length > 0 ? 'more ' : ''}athletes`}
							onClick={() => setPicker(true)} />
					</div>
				</Legend>
				<Legend
					label="Total campaign price">
					<div className="app--summary">
						<p children={athletesCount(summary[0])} />
						<p children={`$${summary[1]}`} />
					</div>
				</Legend>
				<Button
					variant="primary"
					disabled={!athletes.length}
					disabledDeep
					loading={processes}
					label="Checkout"
					onClick={handleSubmit(submit)} />
			</div>
		</>
	);

}
