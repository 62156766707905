import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import { RequestForm } from '~/services';

import {
	BigAmount,
	Button,
	CDatePicker,
	Input,
	Legend
} from '~/components';

export const VideoChat: React.FC<RequestForm.Props> = (props) => {

	const { athlete, onSubmit, processes } = props;

	const { control, handleSubmit, reset } = useForm<RequestForm.VideoChat>();

	const submit = (form: RequestForm.VideoChat) => {

		onSubmit(form, () => reset());

	}

	if (!athlete) {
		return null;
	}

	return (
		<>
			<CDatePicker
				name="date"
				icon="calendar"
				rules={{ required: true }}
				control={control}
				minimumDate={dayjs().add(1, 'minute').toDate()}
				placeholder="Select date" />
			<Input
				name="event_description"
				icon="info"
				rules={{ required: true }}
				control={control}
				textarea
				placeholder="Event description" />
			<Legend
				label="Total price"
				children={(
					<BigAmount
						amount={athlete.athlete_info.athlete_services.video_chat_rate} />
				)} />
			<Button
				label="Book Now"
				variant="primary"
				onClick={handleSubmit(submit)}
				loading={processes} />
		</>
	);

}
