import React, { useCallback, useState } from 'react';

import { SquareIcon } from './SquareIcon';

import { useAuth } from '../store/hooks';
import { handleError, socialLinks } from '../services';

interface SocialLinkProps {
	type: keyof typeof socialLinks
}

export const SocialLink: React.FC<SocialLinkProps> = (props) => {

	const { type } = props;

	const { account, removeSocialLink } = useAuth();

	const [ removing, setRemoving ] = useState(false);

	const [ title, icon ] = socialLinks[type];

	const remove = useCallback(
		async () => {

			setRemoving(true);

			try {

				await removeSocialLink(type);

			} catch (e) {

				handleError(e);

			} finally {

				setRemoving(false);

			}

		},
		[ type, removeSocialLink ]
	);

	if (!account) {
		return null;
	}

	return (
		<div className="app--social-link">
			<SquareIcon
				icon={icon} />
			<div className="link-content">
				<p className="service" children={title} />
				{!!account[type] && (
				<a
					rel="noreferrer"
					href={account[type]}
					target="_blank"
					children={account[type]}
					className="link" />
				)}
			</div>
			{!!account[type] && (
			<SquareIcon
				loading={removing}
				onClick={remove}
				icon={{
					name: 'bin',
					fill: '#4a4a4a'
				}} />
			)}
		</div>
	);

}
