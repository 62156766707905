import { Link, LinkProps } from 'react-router-dom';
import cn from 'classnames';

import { Loader } from './Loader';
import { HTMLButton } from '~/types';

export interface ButtonProps extends HTMLButton {
	to?: string | LinkProps,
	label?: React.ReactNode,
	variant?: 'primary' | 'secondary' | 'hollow',
	loading?: boolean,
	disabledDeep?: boolean
}

export const Button: React.FC<ButtonProps> = (props) => {

	const { to, label, variant, loading, disabledDeep, children, className: _className, ...button } = props;

	const className = cn('app-button', 'mixed-fill', _className, {
		'app-button--clickable': !!button.onClick,
		[`app-button--${variant}`]: variant,
		[`mixed-fill--${variant}`]: variant,
		'app-button--disabled-deep': button.disabled && disabledDeep
	});

	const content = loading ?
		<Loader loading={loading} /> :
		<span>{label}</span>

	if (to) {
		if (typeof to === 'string' && to.indexOf('mailto:') !== -1) {
			return (
				<a
					rel="noreferrer"
					href={to}
					target="_blank"
					children={content}
					className={className} />
			);
		}
		return (
			<Link
				{...typeof to === 'string' ? {} : to}
				to={typeof to === 'string' ? to : to.to}
				children={content}
				className={className} />
		);
	}

	return (
		<button
			children={content}
			disabled={loading}
			className={className}
			{...button} />
	);

}

export const PaddedButton: React.FC<ButtonProps> = (props) => {

	return (
		<div style={{ paddingLeft: 65 }}>
			<Button {...props} />
		</div>
	);

}
