import { iconResolver } from '~/utils';

const icons = ['sport'] as const;

interface AthleteLabelsProps {
	team?: [ string, string ],
	sport?: [ string, string ],
	conference?: [ string, string ]
}

export const AthleteLabels: React.FC<AthleteLabelsProps> = (props) => {

	const { ...ids } = props;

	return (
		<div className="app--athlete-labels">
			{icons.map((icon, i) => (
			<div
				key={i}
				children={ids[icon] && iconResolver({
					id: ids[icon]![0],
					key: icon,
					compact: true,
					fontSize: 13,
					originalName: ids[icon]![1],
				})} />
			))}
		</div>
	);

}
