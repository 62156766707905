import cn from 'classnames';

interface OptionViewProps {
	label: string | React.ReactNode,
	value: string | React.ReactNode,
	phone?: boolean,
	hidden?: boolean,
	vertical?: boolean,
	className?: string
}

export const OptionView: React.FC<OptionViewProps> = (props) => {

	const { hidden, label, value, phone, vertical, className } = props;

	if (hidden) {
		return null;
	}

	return (
		<div className={cn('app-option-view', { vertical, isPhone: !!phone }, className)}>
			<div className="app-option-view--label">{label}</div>
			<div
				className="app-option-view--value"
				{...phone ?
					{ dangerouslySetInnerHTML: { __html: `<a href="tel:${value}">${value}</a>` } } :
					{ children: value }
				} />
		</div>
	);

}
