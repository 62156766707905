import { createModel } from '@rematch/core';

import { Store } from './';

import { Data, DataType, RefDataResponse } from '~/services';

const createMap = <T extends { id: string }>(arr: T[]): Record<string, T> => {
	const ____Map = {} as Record<string, T>;

	arr.forEach((item) => {
		____Map[item.id] = item;
	});

	return ____Map;
}

const state: RefDataResponse & {
	keyed: {
		schools: Record<string, DataType.School>,
		sports: Record<string, DataType.Sport>,
	}
} = {
	iap_apple_products: [],
	stripe_public: '',
	intro_video: '',
	schools: [],
	sports: [],
	keyed: {
		schools: {},
		sports: {},
	},
};

export const data = createModel<Store.General>()({
	state,
	reducers: {
		insert: (state, data: RefDataResponse) => {

			return {
				...state,
				...data,
				keyed: {
					schools: createMap(data.schools),
					sports: createMap(data.sports),
				},
			};

		},
	},
	effects: (dispatch) => ({
		retrieve: async () => {

			const data = await Data.read().promise;

			dispatch.data.insert(data);

		},
	}),
});
