import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { lang } from '~/constants';
import { Account, handleError } from '~/services';
import { Button, VideoButton } from '~/components';

import { StepProps, MediaState } from '~/services/signup';

type Form = {}

export const SignUpVideo: React.FC<StepProps> = (props) => {

	const {
		step: [ , setStep ],
		data: [ data, setData ],
		steps,
		account,
	} = props;

	const [ loading, setLoading ] = useState(false);

	const [ video, setVideo ] = useState<MediaState>({
		file: account?.intro_video || data.video,
	});

	const { handleSubmit } = useForm<Form>();

	const proceed = (form: Form & { video: string }) => {

		setData((data) => ({ ...data, ...form }));

		steps.next && setStep(steps.next);

	}

	const onSubmit = handleSubmit(
		async (form: Form) => {

			if (!video.file) {
				return setVideo({ error: lang.VIDEO_EMPTINESS });
			}

			if (typeof video.file === 'string') {
				return proceed({ ...form, video: video.file });
			}

			try {

				setLoading(true);

				const { path } = await Account.media.video({
					video: video.file,
				}).promise;

				proceed({ ...form, video: path });

			} catch (e) {

				handleError(e);

			} finally {

				setLoading(false);

			}

		}
	);

	return (
		<form onSubmit={onSubmit}>
			<h2>{lang.SIGNUP_VIDEO_TITLE}</h2>
			<VideoButton
				video={video.file}
				error={video.error}
				onError={(error) => setVideo({ error })}
				useDefault
				onVideoChange={(file) => setVideo({ file })} />
			<Button
				label="Setup Profile"
				variant="primary"
				loading={loading} />
		</form>
	);

}
