import { api } from '../api';

export namespace DataType {

	export type Conference = {
		id: string,
		name: string,
		sport_id: string
	}

	export type School = {
		id: string,
		name: string
	}

	export type Sport = {
		id: string,
		name: string
	}

	export type Team = {
		id: string,
		name: string,
		university: string,
		conference_id: string
	}

}

export type RefDataResponse = {
	iap_apple_products: string[],
	stripe_public: string,
	intro_video: string,
	schools: DataType.School[],
	sports: DataType.Sport[]
}

export const Data = {

	read: () => api.get<RefDataResponse>('/public/meta/reference-data'),

};
