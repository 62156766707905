import { RequestViewComponent as RWC } from '~/services';
import { Legend, VideoWrap } from '~/components';

import { RequestPerson, RequestEarnings } from '../Request';

export const Shoutout: RWC<'shoutout'> = (props) => {

	const { request: { profile, ...request }, account } = props;

	const {
		status,
		shoutout,
		request_amount,
	} = request;

	return (
		<>
			{!!(account.user_type !== 2 && status >= 4) && (
			<VideoWrap
				watermark
				source={{ uri: shoutout.video }} />
			)}
			<RequestPerson
				profile={profile}
				request={request} />
			<RequestEarnings
				amount={request_amount} />
			<Legend
				label="List of names to be said in the shout out"
				children={(
					<div className="names--list">
						{shoutout.names_in_shoutout.split(', ').map((name, i) => (
						<p
							key={i}
							children={name} />
						))}
					</div>
				)} />
			<Legend
				label="Special Message Information"
				children={shoutout.special_message_info} />
		</>
	);

}
