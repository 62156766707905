import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { lang } from '~/constants';
import { useAuth } from '~/store';
import { DeepWritableObject } from '~/types';

import { StepProps, MediaStateArray } from '~/services/signup';

import { Button, Input, PhotoButton } from '~/components';

import {
	Account,
	handleError,
	IBusinessInfo,
	isAthlete,
	isBusiness,
	SignUpBusinessInput,
	SignUpFanInput,
	UserRoles
} from '~/services';

type Form = {
	email: string,
	first_name: string,
	last_name: string,
	business: DeepWritableObject<IBusinessInfo>
}

export const SignUpPhoto: React.FC<StepProps> = (props) => {

	const {
		step: [ , setStep ],
		data: [ data, setData ],
		steps,
		account,
	} = props;

	const { profile_photo, business } = data;

	const { authorize } = useAuth();

	const [ loading, setLoading ] = useState(false);

	const [ photo, setPhoto ] = useState<MediaStateArray>({
		file: account?.profile_photo || profile_photo,
	});

	const { control, handleSubmit } = useForm<Form>({
		defaultValues: {
			email: account?.email || data.email,
			first_name: account?.first_name || data.first_name,
			last_name: account?.last_name || data.last_name,
			business: isBusiness(account) ? {
				name: account?.business_info.name || business.name,
				about: account?.business_info.about || business.about,
				category_list: account?.business_info.category_list || business.category_list,
				description: account?.business_info.description || business.description,
				phone: account?.business_info.phone || business.phone || account.phone,
				email: account?.business_info.email || business.email,
				website: account?.business_info.website || business.website,
				single_line_address: account?.business_info.single_line_address || business.single_line_address,
			} : undefined,
		},
	});

	const signUp = useCallback(
		async (form: SignUpFanInput | SignUpBusinessInput) => {

			try {

				setLoading(true);

				const { account } = 'business_info' in form ?
					await Account.signUpBusiness(form).promise :
					await Account.signUpFan(form).promise;

				authorize({ account });

			} catch (e) {

				setLoading(false);

				handleError(e);

			}

		},
		[ authorize ]
	);

	const proceed = (form: Form & { profile_photo: string }) => {

		if (account?.user_type === 12) {
			setData((data) => ({ ...data, ...form }));
			return void (steps.next && setStep(steps.next));
		}

		if (isBusiness(account)) {
			return void signUp({
				business_info: {
					...form.business,
					user_id: account.business_info.user_id,
					picture: form.profile_photo,
				},
			});
		}

		return void signUp(form);

	}

	const onSubmit = handleSubmit(
		async (form: Form) => {

			if (!photo.file) {
				return setPhoto({ error: lang.PHOTO_EMPTINESS });
			}

			if (typeof photo.file === 'string') {
				return proceed({ ...form, profile_photo: photo.file });
			}

			try {

				setLoading(true);

				const { path } = await Account.media.photo({
					photo: photo.file[0],
					...(photo.file[1] ? { second_photo: photo.file[1] } : undefined),
				}).promise;

				proceed({ ...form, profile_photo: path });

			} catch (e) {

				handleError(e);

			} finally {

				setLoading(false);

			}

		}
	);

	return (
		<form onSubmit={onSubmit}>
			<PhotoButton
				photo={photo.file}
				error={photo.error}
				onPhotoChange={(file) => setPhoto({ file })}
				isCrop
				isAthlete={isAthlete(account)} />
			{isBusiness(account) ? (
			<>
				<Input
					name="business.name"
					icon="user2"
					rules={{ required: true }}
					control={control}
					placeholder="Business name" />
				<Input
					name="business.about"
					icon="description"
					control={control}
					textarea
					placeholder="About" />
				<Input
					name="business.category_list"
					icon="description"
					control={control}
					placeholder="Category" />
				<Input
					name="business.description"
					icon="description"
					control={control}
					textarea
					placeholder="Description" />
				<Input
					name="business.phone"
					icon="phone"
					rules={{ required: true }}
					control={control}
					placeholder="Phone" />
				<Input
					name="business.email"
					icon="mail"
					rules={{ required: true }}
					control={control}
					placeholder="Email" />
				<Input
					name="business.website"
					icon="link"
					rules={{ required: true }}
					control={control}
					placeholder="Website" />
				<Input
					name="business.single_line_address"
					icon="mapPin"
					control={control}
					placeholder="Address" />
			</>
			) : (
			<>
				<Input
					name="email"
					icon="mail"
					rules={{ required: true }}
					control={control}
					placeholder="Email" />
				<Input
					name="first_name"
					icon="user2"
					rules={{ required: true }}
					control={control}
					placeholder="First name" />
				<Input
					name="last_name"
					icon={null}
					rules={{ required: true }}
					control={control}
					placeholder="Last name" />
			</>
			)}
			<Button
				label={`Create ${UserRoles[account!.user_type][1]} Account`}
				variant="primary"
				loading={loading} />
		</form>
	);

}
