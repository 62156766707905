import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '~/store';
import { useRoutes } from '~/hooks';
import { subscribeNotify } from '../services/subscribeNotify';

export const AppRoutes: React.FC = () => {

	const [ { status, account, isGuest } ] = useSelector(
		(store: Store.Root) => [ store.auth, store.data ] as const
	);

	const dispatch = useDispatch<Store.Dispatch>();

	const { route, routes, pathname } = useRoutes(
		status,
		account?.user_type
	);

	useEffect(() => {

		dispatch.data.retrieve();

	}, [ dispatch ]);

	useEffect(() => {

		if (status === 1 && !isGuest) {
			dispatch.notify.retrieve({ force: true });
		}

	}, [ dispatch, status, isGuest ]);

	useEffect(() => {

		// diversify
		// if (status === 0) {}
		dispatch.auth.retrieve();

	}, [ dispatch, status, pathname ]);

	useEffect(() => {

		if (status !== 1 || isGuest) {
			return;
		}

		const unsubscribe = subscribeNotify((event) => {

			if (event.type === 'notification') {
				dispatch.notify._append(event.payload);
			}

		});

		return () => unsubscribe();

	}, [ status, isGuest ]);

	const children = (
		<Switch>
			{routes.map((route, i) => (
			<Route key={i} {...route} />
			))}
			{routes[0] && <Redirect to={routes[0].path as string} />}
		</Switch>
	);

	if (route?.frame) {
		return <route.frame children={children} />
	}

	return children;

}
